.box {
  position: relative;
  height: 64em;
  padding: 0 10%;
  background-color: #f9f9f9;
}
.labelTitle {
  margin-top: 31px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 47px;
  color: #ff800c;
}
.boxContainImg {
  height: 120px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.boxDetailsImg {
  width: 112.59px;
  height: 110px;
}
.boxDetailsImg1 {
  width: 118.02px;
  height: 100px;
}
.boxDetailsImg2 {
  width: 96.8px;
  height: 100px;
}
.boxDetailsLabel {
  width: 280px;
  /* height: 35px; */
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  color: #626262;
}
.boxArrowImg {
  width: 55px;
  height: 20px;
}
.boxDetailsText {
  width: 619px;
  margin-left: 12px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #626262;
}
.labelTextTitle {
  height: 47px;
  margin: 50px 0 30px 0;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 47px;
  color: #ff800c;
}
.boxTools {
  position: relative;
  width: 250px;
  height: 250px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.boxToolsImg1 {
  position: absolute;
  width: 19.69px;
  height: 20px;
  left: 77px;
  top: 42px;
}
.boxToolsTitle1 {
  position: absolute;
  width: 87px;
  height: 26px;
  left: 97px;
  top: 39px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #ff800c;
}
.boxToolsText {
  position: absolute;
  width: 206px;
  height: 84px;
  left: 25px;
  top: 99px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #626262;
}
.boxToolsSubTitle {
  position: absolute;
  width: 48px;
  height: 28px;
  left: 101px;
  top: 201px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #fb7b04;
  cursor: pointer;
}
.boxToolsTitleImg {
  position: absolute;
  width: 206px;
  height: 63px;
  left: 29px;
  top: 19px;
}
