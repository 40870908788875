.box {
  position: relative;
  height: 64em;
  padding: 30px 10% 0 10%;
  background: #f9f9f9;
}
.labelTitle {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  color: #ff800c;
}
.btnTool {
  position: relative;
  width: 100px;
  height: 44px;
  background: #ff800c;
  border-radius: 4px;
  cursor: pointer;
}
.btnToolText {
  position: absolute;
  width: 48px;
  height: 28px;
  left: 26px;
  top: 8px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
}
.labelTitleFeatures {
  width: 100%;
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #626262;
}
.boxFeatures {
  position: absolute;
  width: 888px;
  height: 408px;
  left: 276px;
  top: 142px;
}
.boxFeaturesSub {
  position: relative;
  width: 250px;
  height: 194px;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.boxFeaturesIcon {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 110px;
  top: 8px;
}
.boxFeaturesText {
  position: absolute;
  width: 213px;
  height: 105px;
  left: 20px;
  top: 61px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  line-height: 21px;
  color: #626262;
}
.boxTable {
  position: relative;
  width: 888px;
  height: 365px;
  background: #eeeeee;
}
.boxTableTitleBar {
  position: relative;
  width: 888px;
  height: 58px;
  background: #ff800c;
}
.boxTableTitleText {
  position: relative;
  width: 146px;
  height: 35px;
  left: 370px;
  top: 10px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ffffff;
}
.boxMiddleBar {
  position: absolute;
  width: 2.66px;
  height: 365px;
  left: 440.67px;
  background: #ff800c;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.boxTableContentText {
  position: absolute;
  width: auto;
  height: auto;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
}
.boxTableRowBar {
  position: absolute;
  width: 888px;
  height: 3.14px;
  background: #ff800c;
}
