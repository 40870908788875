.box {
  width: 100%;
  padding: 0 10% 40px 10%;
  margin-top: 80px;
  background-color: #ffffff;
}
.title {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: #626262;
}
.boxInputLeft {
  height: 210px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 500px;
}
.inputName {
  width: 100%;
  height: 54px;
  border: 4px solid #ff800c;
  box-sizing: border-box;
  border-radius: 21px;
}
.inputEmail {
  width: 100%;
  height: 54px;
  border: 4px solid #ff800c;
  box-sizing: border-box;
  border-radius: 21px;
}
.inputPhone {
  width: 100%;
  height: 54px;
  border: 4px solid #ff800c;
  box-sizing: border-box;
  border-radius: 21px;
}
.boxInputRight {
  height: 210px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 500px;
}
.inputCompanyName {
  width: 100%;
  height: 54px;
  border: 4px solid #ff800c;
  box-sizing: border-box;
  border-radius: 21px;
}
.inputCompanySite {
  width: 100%;
  height: 54px;
  border: 4px solid #ff800c;
  box-sizing: border-box;
  border-radius: 21px;
}
.boxInputBottom {
  width: 500px;
}
.inputMessage {
  width: 100%;
  border: 4px solid #ff800c;
  box-sizing: border-box;
  border-radius: 21px;
}
.btnSendEmail {
  width: 160px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff800c;
  border: 4px solid #ff800c;
  box-sizing: border-box;
  border-radius: 42px;
  cursor: pointer;
}
.btnSendEmailText {
  width: 100%;
  height: 23px;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
}
