@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: "Roboto Condensed", sans-serif;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
/* input:-internal-autofill-selected {
  appearance: menulist-button;
  background-color: red !important;
  background-image: none !important;
  color: blue !important;
} */

/* -------------- Arrow Stepper CSS ------------------  */

.Arrows {
	display: flex;
	justify-content: center;
	height: 50px;
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#ff800c),
		to(#ff800c)
	);
	/* background: linear-gradient(to right, #24d191, #30b2f3); */
}

.Arrows--step {
	flex-basis: 0;
	flex-grow: 1;
	position: relative;
	max-width: 200px;
}

.Arrows--step--arrow,
.Arrows--step--content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.Arrows--step--content {
	box-sizing: border-box;
	display: flex;
	padding: 0 20px;
	justify-content: center;
	align-items: center;
}

.Arrows--step--number {
	font-size: 16px;
	font-size: 1rem;
	font-weight: 600;
	opacity: 0.5;
}

.Arrows--step--number::after {
	content: ".";
	margin-right: 4px;
}

.Arrows--step--label {
	font-size: 12px;
	font-size: 0.75rem;
	font-weight: 600;
	color: #fff;
}

.Arrows--step__current .Arrows--step--arrow {
	fill: #e0e0e0;
}
.Arrows--step__current .Arrows--step--label {
	color: #000;
	opacity: 0.5;
}

@media (min-width: 540px) {
	.Arrows--step--number {
		font-size: 0;
	}

	.Arrows--step--label {
		font-size: 1.2rem;
	}
}

/* ----------------------- Swiper Js Styles ------------------------ */

.swiper {
	width: 100%;
	height: 100%;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;

	/* Center slide text vertically */
	display: flex;
	justify-content: center;
	align-items: center;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

/* styles for marquee class */

.marquee {
	width: 100%;
	line-height: 50px;
	color: white;
	white-space: nowrap;
	overflow: hidden;
	box-sizing: border-box;
}
.marquee p {
	display: inline-block;
	padding-left: 100%;
	-webkit-animation: marquee 15s linear infinite;
	        animation: marquee 15s linear infinite;
}
@-webkit-keyframes marquee {
	0% {
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}
	100% {
		-webkit-transform: translate(-100%, 0);
		        transform: translate(-100%, 0);
	}
}
@keyframes marquee {
	0% {
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}
	100% {
		-webkit-transform: translate(-100%, 0);
		        transform: translate(-100%, 0);
	}
}

/* custome color css for react date range picker third party component  */

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
	color: #fc800e;
}

.rdrDayToday .rdrDayNumber span:after {
	background: #fc800e;
}

.rdrDateDisplayItemActive {
	border-color: #fc800e;
}

.Page1_box__3Wb8T {
  position: relative;
  padding: 0 10%;
  height: 64rem;
}
.Page1_boxBgImage__3EYNt {
  position: absolute;
  width: 90em;
  height: 64em;
}
.Page1_boxContent__ie8hD {
  position: relative;
  background-color: #ffffff;
  /* width: 82.62em; */
  height: 42.43em;
  /* left: 59px; */
  top: 173px;
}
.Page1_boxLogo__1UT6Z {
  position: relative;
  left: 28px;
  top: 33px;
  cursor: pointer;
}
.Page1_Ellipse14__2Imz7 {
  width: 196px;
  height: 102px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
}
.Page1_HOTQr__3Vmt0 {
  width: 150px;
  height: 90px;
}
.Page1_boxMenu__jDsJG {
  position: absolute;
  width: 496px;
  height: 69px;
  right: 28px;
  top: 33px;
  border: 4px solid #ff800c;
  box-sizing: border-box;
  border-radius: 42px;
}
.Page1_boxMenuItems__3pTba {
  position: absolute;
  width: 108px;
  height: 28px;
  top: 17px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #ff800c;
  cursor: pointer;
}
.Page1_labelTitle__3G88C {
  position: absolute;
  width: auto;
  height: 59px;
  /* left: 374px; */
  top: 228px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 59px;
  color: #ff800c;
}
.Page1_labelText__21YgW {
  position: absolute;
  width: 845px;
  height: 232px;
  /* left: 238px; */
  top: 297px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  text-align: center;
  color: #403e3e;
}

.Page2_box__2s6j0 {
  position: relative;
  height: 64em;
  padding: 0 10%;
  background: #ffffff;
}
.Page2_labelTitle__WYJ58 {
  width: auto;
  height: 47px;
  margin-top: 20px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 47px;
  color: #ff800c;
}
.Page2_labelText__pvtuO {
  width: 767px;
  /* height: 105px; */
  /* left: 331px; */
  /* margin-top: 63px; */
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #626262;
}
.Page2_interRemoveBgPreview__2jaOm {
  position: relative;
  width: 307.06px;
  height: 300px;
  /* left: 553px; */
  /* margin-top: 168px; */
}
.Page2_labelSubTitle__3PCDJ {
  width: 150px;
  /* height: 47px; */
  /* left: 640px; */
  /* margin-top: 480px; */
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 47px;
  color: #ff800c;
}
.Page2_boxTable__ZqcYC {
  position: relative;
  max-width: 100%;
  background: #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Page2_boxTitleLabel__3sGxZ {
  width: 100%;
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 65px;
  color: #ffffff;
}
.Page2_boxTableTopbar__1IGnU {
  display: flex;
  align-items: center;
  width: 100%;
  height: 65px;
  background: #ff800c;
}
.Page2_boxMiddelBar__11PF5 {
  position: absolute;
  width: 4px;
  height: 80.5%;
  top: 5px;
  background: #ff800c;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0);
}
.Page2_boxRowBar__2wRye {
  width: 100%;
  height: 4px;
  background: #ff800c;
}
.Page2_labelTabelText__3F9zO {
  font-family: "Raleway", sans-serif;
  width: 40%;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #626262;
}

.Page2_labelTabelTextLastNode__hWN3X {
  font-family: "Raleway", sans-serif;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #626262;
}

.Page3_box__25OGt {
  position: relative;
  height: 64em;
  padding: 0 10%;
  background-color: #f9f9f9;
}
.Page3_labelTitle__3HhnI {
  margin-top: 31px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 47px;
  color: #ff800c;
}
.Page3_boxContainImg__3KENQ {
  height: 120px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Page3_boxDetailsImg__kRIPb {
  width: 112.59px;
  height: 110px;
}
.Page3_boxDetailsImg1__38uAG {
  width: 118.02px;
  height: 100px;
}
.Page3_boxDetailsImg2__DNuqu {
  width: 96.8px;
  height: 100px;
}
.Page3_boxDetailsLabel__36J4j {
  width: 280px;
  /* height: 35px; */
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  color: #626262;
}
.Page3_boxArrowImg__3oIw3 {
  width: 55px;
  height: 20px;
}
.Page3_boxDetailsText__1sdn_ {
  width: 619px;
  margin-left: 12px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #626262;
}
.Page3_labelTextTitle__1ZlAy {
  height: 47px;
  margin: 50px 0 30px 0;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 47px;
  color: #ff800c;
}
.Page3_boxTools__1GUa4 {
  position: relative;
  width: 250px;
  height: 250px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.Page3_boxToolsImg1__LHaka {
  position: absolute;
  width: 19.69px;
  height: 20px;
  left: 77px;
  top: 42px;
}
.Page3_boxToolsTitle1__1NzAa {
  position: absolute;
  width: 87px;
  height: 26px;
  left: 97px;
  top: 39px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #ff800c;
}
.Page3_boxToolsText__2aRf5 {
  position: absolute;
  width: 206px;
  height: 84px;
  left: 25px;
  top: 99px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #626262;
}
.Page3_boxToolsSubTitle__1kcBi {
  position: absolute;
  width: 48px;
  height: 28px;
  left: 101px;
  top: 201px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #fb7b04;
  cursor: pointer;
}
.Page3_boxToolsTitleImg__2pv9r {
  position: absolute;
  width: 206px;
  height: 63px;
  left: 29px;
  top: 19px;
}

.Page4_box__2fywc {
  position: relative;
  height: 82em;
  padding: 0 10%;
  background: #ffffff;
}
.Page4_labelTitle__1RGdD {
  height: 35px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  color: #ff800c;
  cursor: pointer;
}
.Page4_btnTool__2BiKy {
  position: relative;
  width: 100px;
  height: 44px;
  background: #ff800c;
  border-radius: 4px;
  cursor: pointer;
}
.Page4_btnToolText__39VpI {
  position: absolute;
  width: 48px;
  height: 28px;
  left: 26px;
  top: 8px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
}
.Page4_labelFeatures__1x9DT {
  width: 100%;
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 20px;
  text-align: center;
  color: #626262;
}
.Page4_boxFeatures__2VjdT {
  position: absolute;
  width: auto;
  height: 532px;
  top: 113px;
}
.Page4_boxFeaturesSub__1tZYY {
  width: 100%;
  height: 255px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.Page4_boxFeaturesImg__3P6Ko {
  width: 35.71px;
  height: 35px;
  margin: 0px 0 15px 0;
}
.Page4_boxFeaturesText__3YrTR {
  width: 92%;
  display: flex;
  flex-direction: column;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #626262;
}
.Page4_boxFeaturesBottomText__v4PuK {
  position: absolute;
  width: 90%;
  height: auto;
  left: 5px;
  top: 71px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #626262;
}
.Page4_boxTable__26-rb {
  position: relative;
  width: 888px;
  height: 322px;
  background: #eeeeee;
}
.Page4_boxTableTitleBar___4_js {
  position: absolute;
  width: 888px;
  height: 50px;
  background: #ff800c;
}
.Page4_boxTableTitleText__23Zz0 {
  position: absolute;
  width: auto;
  height: 35px;
  left: 370px;
  top: 5.23px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ffffff;
}
.Page4_boxMiddleBar__aSQjx {
  position: absolute;
  width: 2.66px;
  height: 319.23px;
  left: 440.67px;
  background: #ff800c;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0);
}
.Page4_boxTableContentText__T7LY3 {
  position: absolute;
  width: auto;
  height: auto;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
}
.Page4_boxTableRowBar__lC4bw {
  position: absolute;
  width: 888px;
  height: 2.77px;
  background: #ff800c;
}

.Page5_box__Qn1DU {
  position: relative;
  height: 64em;
  padding: 30px 10% 0 10%;
  background: #f9f9f9;
}
.Page5_labelTitle__2j3Xj {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  color: #ff800c;
}
.Page5_btnTool__hYhbM {
  position: relative;
  width: 100px;
  height: 44px;
  background: #ff800c;
  border-radius: 4px;
  cursor: pointer;
}
.Page5_btnToolText__10Cfk {
  position: absolute;
  width: 48px;
  height: 28px;
  left: 26px;
  top: 8px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
}
.Page5_labelTitleFeatures__pR5uw {
  width: 100%;
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #626262;
}
.Page5_boxFeatures__3Y_dd {
  position: absolute;
  width: 888px;
  height: 408px;
  left: 276px;
  top: 142px;
}
.Page5_boxFeaturesSub__tfe5P {
  position: relative;
  width: 250px;
  height: 194px;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.Page5_boxFeaturesIcon__2tjht {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 110px;
  top: 8px;
}
.Page5_boxFeaturesText__3SFvS {
  position: absolute;
  width: 213px;
  height: 105px;
  left: 20px;
  top: 61px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  line-height: 21px;
  color: #626262;
}
.Page5_boxTable__3LBQN {
  position: relative;
  width: 888px;
  height: 365px;
  background: #eeeeee;
}
.Page5_boxTableTitleBar__3SbVY {
  position: relative;
  width: 888px;
  height: 58px;
  background: #ff800c;
}
.Page5_boxTableTitleText__1zXbm {
  position: relative;
  width: 146px;
  height: 35px;
  left: 370px;
  top: 10px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ffffff;
}
.Page5_boxMiddleBar__3LMY8 {
  position: absolute;
  width: 2.66px;
  height: 365px;
  left: 440.67px;
  background: #ff800c;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0);
}
.Page5_boxTableContentText__q2QaK {
  position: absolute;
  width: auto;
  height: auto;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
}
.Page5_boxTableRowBar___0MV9 {
  position: absolute;
  width: 888px;
  height: 3.14px;
  background: #ff800c;
}

.Page6_box__3DNMr {
  position: relative;
  height: 64em;
  padding: 30px 10% 0 10%;
  background: #ffffff;
}
.Page6_labelTitle__1gGfp {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  color: #ff800c;
}
.Page6_btnTool__2wKBE {
  position: relative;
  width: 100px;
  height: 44px;
  background: #ef7505;
  border-radius: 4px;
  cursor: pointer;
}
.Page6_btnToolText__QkNpP {
  position: absolute;
  width: 48px;
  height: 28px;
  left: 26px;
  top: 8px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
}
.Page6_labelFeatures__11gce {
  width: 100%;
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #626262;
}
.Page6_boxFeatures__1yktO {
  position: absolute;
  width: 888px;
  height: 408px;
  left: 276px;
  top: 158px;
}
.Page6_boxFeaturesSub__3fBtN {
  position: relative;
  width: 250px;
  height: 194px;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.Page6_boxFeaturesIcon__1iaLR {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 110px;
  top: 12px;
}
.Page6_boxFeaturesText__3DWwQ {
  position: absolute;
  width: 213px;
  height: 105px;
  left: 20px;
  top: 60px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  line-height: 21px;
  color: #626262;
}
.Page6_boxTable__GvIxZ {
  position: relative;
  width: 888px;
  height: 331px;
  background: #eeeeee;
}
.Page6_boxTableTitleBar__fAt7H {
  position: relative;
  width: 888px;
  height: 67px;
  background: #ff800c;
}
.Page6_boxTableTitleText__3gCm8 {
  position: relative;
  width: 146px;
  height: 35px;
  left: 370px;
  top: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ffffff;
}
.Page6_boxMiddleBar__1G3i_ {
  position: absolute;
  width: 2.66px;
  height: 240px;
  left: 440.67px;
  background: #ff800c;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0);
}
.Page6_boxTableContentText__1MF9S {
  position: absolute;
  width: auto;
  height: auto;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
}
.Page6_boxTableRowBar__3O9ME {
  position: absolute;
  width: 888px;
  height: 3.14px;
  background: #ff800c;
}

.Page7_box__2S60P {
  position: relative;
  height: 64em;
  padding: 30px 10% 0 10%;
  background: #f9f9f9;
}
.Page7_labelTitle__3Mh15 {
  width: auto;
  height: 35px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  color: #ff800c;
}
.Page7_btnTool__18GMc {
  position: relative;
  width: 100px;
  height: 44px;
  background: #ef7505;
  border-radius: 4px;
  cursor: pointer;
}
.Page7_btnToolText__3EBs- {
  position: absolute;
  width: 48px;
  height: 28px;
  left: 26px;
  top: 8px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
}
.Page7_labelFeatures__oI5X0 {
  width: 100%;
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #626262;
}
.Page7_boxFeatures__2oSwO {
  position: absolute;
  width: 888px;
  height: 408px;
  left: 276px;
  top: 158px;
}
.Page7_boxFeaturesSub__1pvL7 {
  position: relative;
  width: 250px;
  height: 194px;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.Page7_boxFeaturesIcon__2Ypnz {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 110px;
  top: 12px;
}
.Page7_boxFeaturesText__3omVt {
  position: absolute;
  width: 213px;
  height: 105px;
  left: 20px;
  top: 60px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  line-height: 21px;
  color: #626262;
}
.Page7_boxTable__gh8A1 {
  position: relative;
  width: 888px;
  height: 331px;
  background: #eeeeee;
}
.Page7_boxTableTitleBar__3Gf-_ {
  position: relative;
  width: 888px;
  height: 67px;
  background: #ff800c;
}
.Page7_boxTableTitleText__3w3pH {
  position: relative;
  width: 146px;
  height: 35px;
  left: 370px;
  top: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ffffff;
}
.Page7_boxMiddleBar__3oJ2- {
  position: absolute;
  width: 2.66px;
  height: 240px;
  left: 440.67px;
  background: #ff800c;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0);
}
.Page7_boxTableContentText__2RfGM {
  position: absolute;
  width: auto;
  height: auto;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
}
.Page7_boxTableRowBar__owOVM {
  position: absolute;
  width: 888px;
  height: 3.14px;
  background: #ff800c;
}

.Footer_box__2a-gF {
  position: relative;
  padding: 0 10%;
  background: #ff800c;
}
.Footer_boxInputMain__3sTwS {
  display: flex;
  padding: 20px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #ffffff;
}
.Footer_boxInputTitle__j5fmP {
  width: 100%;
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #2d2c2c;
  margin-bottom: 10px;
}
.Footer_inputName__1KRkR {
  width: 75%;
  height: 54px;
}
.Footer_inputEmail__Z6xTW {
  width: 75%;
  height: 54px;
}
.Footer_inputSubject__3arR_ {
  width: 75%;
  height: 54px;
}
.Footer_inputMessage__10nov {
  width: 75%;
}
.Footer_btnSendMessage__3neJM {
  position: relative;
  width: 160px;
  height: 52px;
  background: #ff800c;
  border: 4px solid #ff800c;
  box-sizing: border-box;
  border-radius: 42px;
  cursor: pointer;
}
.Footer_btnSendMessageText__3ig8k {
  position: absolute;
  width: 145px;
  height: 32px;
  left: 6px;
  top: 10px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #f9f9f9;
}
.Footer_boxContact__q6FfR {
  padding: 30px 30px 20px 50px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.Footer_boxContactTextTitle__ddwOZ {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: #ffffff;
}
.Footer_boxContactText__1v2lj {
  margin-top: 30px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 35px;
  color: #ffffff;
}
.Footer_boxIcon__2h0dR {
  height: 30px;
  margin: 10px 0;
}
.Footer_iconText__1Gip3 {
  margin-left: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 23px;
  line-height: 20px;
  color: #ffffff;
}
.Footer_boxFooter__2hQSf {
  position: relative;
  width: 100%;
}
.Footer_labelFooterTitle__2zDd7 {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 7px;
}
.Footer_labelFooterText__1EZbN {
  margin: 4px 0;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  cursor: pointer;
}
.Footer_boxLogoContainer__3UpSB {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  width: 250px;
}
.Footer_footerSocialIconLinkedIn__3rH5t {
  width: 40px;
  height: 40px;
  border-radius: 200px;
  cursor: pointer;
}
.Footer_footerSocialIcon__axnB4 {
  width: 40px;
  height: 40px;
  /* margin-left: 39px; */
  border-radius: 200px;
  cursor: pointer;
}
.Footer_footerSocialIconMail__5Lhy5 {
  width: 66.35px;
  height: 50px;
  /* margin-left: 27px; */
  border-radius: 200px;
  cursor: pointer;
}
.Footer_footerCopyrights__2t01_ {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
}

.Page1_box__2z-pB {
	position: relative;
	width: 100%;
	height: 950px;
	padding: 0 5%;
	background-color: #ffffff;
}
.Page1_boxLogo__3sxHl {
	position: absolute;
	top: 2em;
	cursor: pointer;
}
.Page1_Ellipse14__153Eh {
	width: 217px;
	height: 102px;
	box-sizing: border-box;
}
.Page1_HOTQr__2oJxZ {
	width: 150px;
	height: 90px;
}
.Page1_boxHead__3hbDu {
	position: relative;
	width: 825.25px;
	height: 299.75px;
	margin-top: 76px;
	background: #f9f9f9;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
}
.Page1_boxHeadTitle__3k6J5 {
	position: relative;
	width: 100%;
	height: 35px;
	text-align: center;
	top: 49px;
	font-family: Raleway;
	font-style: normal;
	font-weight: 500;
	font-size: 30px;
	line-height: 35px;
	color: #ff800c;
}
.Page1_boxHeadText__3zyo3 {
	position: absolute;
	width: 454px;
	height: 46px;
	left: 184.62px;
	top: 106.5px;
	font-family: Raleway;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 23px;
	text-align: center;
	color: #626262;
}
.Page1_boxHeadButton__3i67L {
	position: absolute;
	width: 198.25px;
	height: 32.75px;
	left: 302.88px;
	top: 212px;
	background: #ff800c;
	border-radius: 8px;
	cursor: pointer;
}
.Page1_boxHeadButtonText__10zcZ {
	position: relative;
	width: 147px;
	height: 23px;
	left: 25.62px;
	top: 4.87px;
	font-family: Raleway;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 23px;
	text-align: center;
	color: #ffffff;
}
.Page1_QrLogo__14XgN {
	position: relative;
	width: 252px;
	height: 256px;
	margin-top: 40px;
	z-index: 5;
}
.Page1_qrText__2kgF2 {
	font-family: Raleway;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 23px;
	text-align: center;
	color: #626262;
}

.Page2_box__1RMJ0 {
  position: relative;
  width: 100%;
  padding: 0 10%;
  background-color: #ffffff;
}
.Page2_boxTitle__A7qRs {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: #ff800c;
}
.Page2_boxsubTitle__11AwB {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #626262;
}
.Page2_boxContent__341OV {
  position: relative;
  left: 57px;
  top: 100px;
}
.Page2_boxContentboxes__1giEa {
  position: relative;
  width: 373px;
  height: 266px;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.Page2_boxContentboxesText__1FmGR {
  position: absolute;
  width: 317px;
  height: 202px;
  left: 26px;
  top: 23px;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #626262;
}

.Page3_box__8NXFO {
  width: 100%;
  padding: 0 10%;
  margin-top: 62px;
  background-color: #ffffff;
}
.Page3_title__3BQ3S {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: #ff800c;
}
.Page3_subTitle__26PtS {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #626262;
}
.Page3_boxTable__3wFTc {
  width: 100%;
  padding: 0 80px;
  margin-top: 20px;
}
.Page3_boxTableRow__3Ydoi {
  width: 100%;
  /* background-color: burlywood; */
}
.Page3_tableLeftText__1zFMJ {
  width: 100%;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #000000;
}
.Page3_tableRightText__2BocR {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #626262;
}
.Page3_tableRow__zA4Qh {
  width: 100%;
  height: 2px;
  border: 1px solid #626262;
}
.Page3_tableMiddleColumn__142Vi {
  width: 2px;
  height: 100%;
  border: 1px solid #626262;
}

.Page4_box__3GlyP {
  width: 100%;
  margin-top: 88px;
  padding: 0 10% 40px 10%;
  background-color: #ffffff;
}
.Page4_title__1LCgF {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ff800c;
}
.Page4_subTitle__2LDvB {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  color: #626262;
}
.Page4_labelText__3ywOe {
  width: 373px;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  color: #626262;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.Page1_box__2N7wN {
  width: 100%;
  padding: 0 5%;
  background-color: #ffffff;
}
.Page1_boxLogo__1mvyD {
  position: absolute;
  top: 1.4375em;
  cursor: pointer;
}
.Page1_Ellipse14__3cZGp {
  width: 217px;
  height: 102px;
  box-sizing: border-box;
}
.Page1_HOTQr__3TOag {
  width: 150px;
  height: 90px;
}
.Page1_boxHead__2uiUs {
  position: relative;
  width: 825.25px;
  height: 165px;
  margin-top: 76px;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.Page1_boxHeadTitle__1EKK4 {
  position: relative;
  width: 100%;
  height: 35px;
  left: 200px;
  top: 34px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: #ff800c;
}
.Page1_boxHeadText__2qBPB {
  position: absolute;
  width: 454px;
  height: 46px;
  left: 184.62px;
  top: 106.5px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #626262;
}
.Page1_boxHeadButton__3jfqV {
  position: absolute;
  width: 311px;
  height: 46px;
  left: 245px;
  top: 102px;
  background: #ff800c;
  border-radius: 8px;
  cursor: pointer;
}
.Page1_boxHeadButtonText__2ho6E {
  position: relative;
  width: 147px;
  height: 23px;
  left: 14px;
  top: 11px;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
}
.Page1_labelTitle__1crYv {
  margin-top: 40px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #626262;
}
.Page1_boxStar__3UTN9 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Page1_boxStarVerticalLine__2SXgi {
  width: 0px;
  height: 70px;
  border: 2px solid #ff800c;
}
.Page1_starImg__RYmKx {
  width: 600px;
  height: 600px;
}
.Page1_vectorImg__2EiiZ {
  position: absolute;
  width: 320px;
  height: 292px;
  left: 136px;
  top: 234px;
}
.Page1_boxImg1__i8UKm {
  position: absolute;
  width: 88.59px;
  height: 90px;
  left: 253px;
  top: 144px;
}
.Page1_boxImg2__r-5bQ {
  position: absolute;
  width: 88.59px;
  height: 90px;
  left: 459px;
  top: 320px;
}
.Page1_boxImg3__2rL06 {
  position: absolute;
  width: 88.59px;
  height: 90px;
  left: 390px;
  top: 570px;
}
.Page1_boxImg4__3rs7_ {
  position: absolute;
  width: 88.59px;
  height: 90px;
  left: 119px;
  top: 570px;
}
.Page1_boxImg5__1CCCl {
  position: absolute;
  width: 88.59px;
  height: 90px;
  left: 42px;
  top: 320px;
}
.Page1_qrIcon__2d40j {
  position: absolute;
}
.Page1_icon__1wCBQ {
  position: absolute;
}
.Page1_starText__1OSsA {
  position: absolute;
  width: auto;
  height: 23px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #626262;
}
.Page1_boxRound__3HAg9 {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: linear-gradient(0deg, #ff800c, #ff800c), #ff800c;
}

.Page2_box__2Iwcl {
  width: 100%;
  margin-top: 80px;
  padding: 0 10%;
  background-color: #fff;
}
.Page2_labelTitle__2Lw2c {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  color: #ff800c;
}
.Page2_boxContent__3G-wd {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.Page2_labelBoxNumber__3zA41 {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #ff800c;
}
.Page2_labelNumber__1pBVf {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 35px;
  color: #ff800c;
}
.Page2_labelText__2Pt50 {
  display: flex;
  flex-direction: column;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #626262;
}

.Page3_box__Kz-SI {
  width: 100%;
  padding: 0 10%;
  margin-top: 80px;
  background-color: #ffffff;
}
.Page3_title__2fDYH {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ff800c;
}
.Page3_subTitle__2lfv7 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #626262;
}
.Page3_boxContent__3e8Y4 {
  padding: 35px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.Page3_toolIcon__vrNY5 {
  width: 131.12px;
  height: 100px;
  object-fit: contain;
}
.Page3_toolText__3JvSn {
  display: flex;
  flex-direction: column;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #626262;
}

.Page4_box__1gZcu {
  width: 100%;
  padding: 0 10%;
  margin-top: 80px;
  background-color: #ffffff;
}
.Page4_title__5Nw6a {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ff800c;
}
.Page4_boxContent__1A0-k {
  height: 100%;
  width: 100%;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.Page4_labelText__21QLQ {
  display: flex;
  flex-direction: column;
  padding: 25px;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #626262;
}

.Page5_box__25nXW {
  width: 100%;
  padding: 0 10% 40px 10%;
  margin-top: 80px;
}
.Page5_title__1yINn {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ff800c;
}
.Page5_labelText__2f9xp {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #626262;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.Page1_box__2a6qS {
  width: 100%;
  padding: 0 5%;
  background-color: #fff;
}
.Page1_boxLogo__3t_7c {
  position: absolute;
  top: 1.4375em;
  cursor: pointer;
}
.Page1_HOTQr__2sGqX {
  width: 150px;
  height: 90px;
}
.Page1_boxHead__3QaAR {
  position: relative;
  width: 825.25px;
  height: 299.75px;
  margin-top: 76px;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.Page1_boxHeadTitle__37vvw {
  position: relative;
  float: left;
  width: 100%;
  height: 35px;
  top: 49px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ff800c;
}
.Page1_boxHeadText__3vGI9 {
  position: absolute;
  width: 454px;
  height: 46px;
  left: 184.62px;
  top: 106.5px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #626262;
}
.Page1_boxHeadButton__1KUao {
  position: absolute;
  width: 311px;
  height: 46px;
  left: 246px;
  top: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff800c;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
}
.Page1_boxHeadButtonText__1HAH5 {
  position: relative;
  float: left;
  width: 100%;
  height: 23px;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
}

.Page2_box__ZzsAc {
  width: 100%;
  padding: 0 10%;
  margin-top: 80px;
  background-color: #fff;
}
.Page2_boxTitle__1MIAV {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ff800c;
}
.Page2_boxsubTitle__2FNA2 {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #626262;
}
.Page2_labelText__2VoYt {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  padding: 20px;
  height: 100%;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #626262;
}

.Page3_box__3on-D {
  width: 100%;
  padding: 0 10%;
  margin-top: 80px;
  background-color: #ffffff;
}
.Page3_boxTitle__2qabf {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ff800c;
}
.Page3_labelText__1JErb {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 0 20px;
  height: 100%;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #626262;
}

.Page4_box__2ZDTb {
  width: 100%;
  padding: 0 10% 40px 10%;
  margin-top: 80px;
  background-color: #ffffff;
}
.Page4_title__q11dL {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ff800c;
}
.Page4_boxTabelCell__2kx9P {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Page4_tableHeaderText__3okpw {
  width: 199px;
  height: 27px;
  margin-bottom: 28px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  color: #ffffff;
}
.Page4_boxHeaderPlanBtn__1XKMF {
  width: 201px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 12px;
  cursor: pointer;
}
.Page4_boxHeaderPlanBtnText__n71VF {
  cursor: pointer;
  width: 136px;
  height: 27px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  color: #ffffff;
}
.Page4_tableBodyRow__2FHxH {
  height: 66px;
  border-right: 4px solid #ff800c;
}
.Page4_tableBodyText__2yZ_3 {
  width: 136px;
  height: 27px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  color: #626262;
}

.Page1_box__16ujh {
  width: 100%;
  padding: 0 5%;
  background-color: #ffffff;
}
.Page1_boxLogo__3KVZP {
  position: absolute;
  top: 1.4375em;
  cursor: pointer;
}
.Page1_HOTQr__-kqAJ {
  width: 150px;
  height: 90px;
}
.Page1_boxHead__3nEp- {
  position: relative;
  width: 825.25px;
  height: 299.75px;
  margin-top: 76px;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.Page1_boxHeadTitle__LvxKo {
  position: relative;
  float: left;
  width: 100%;
  height: 35px;
  top: 49px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ff800c;
}
.Page1_boxHeadText__ze945 {
  position: relative;
  float: left;
  width: 652px;
  height: 46px;
  left: 87px;
  top: 56.5px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #626262;
}
.Page1_boxHeadButton__Sx632 {
  position: absolute;
  width: 311px;
  height: 46px;
  left: 246px;
  top: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff800c;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
}
.Page1_boxHeadButtonText__3L3RC {
  position: relative;
  float: left;
  width: 100%;
  height: 23px;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
}

.Page2_box__2DSvV {
  width: 100%;
  padding: 0 10%;
  margin-top: 80px;
  background-color: #ffffff;
}
.Page2_boxTitle__3INuc {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ff800c;
}
.Page2_boxsubTitle__3hkGP {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #626262;
}
.Page2_boxTitle2__30miV {
  margin-top: 20px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ff800c;
}
.Page2_labelText__3HRGk {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #626262;
}
.Page2_Icon__2ZMWB {
  margin-top: 10px;
  width: 298.79px;
  height: 300px;
}

.Page3_box__2s3W9 {
  width: 100%;
  margin-top: 80px;
  padding: 0 10%;
  background-color: #ffffff;
}
.Page3_qrIcon__1qYaT {
  width: 352.3px;
  height: 350px;
}
.Page3_middleLine__2QRvZ {
  width: 6px;
  height: 450px;
  background: #ff800c;
}
.Page3_Text__3j-m8 {
  display: flex;
  flex-direction: column;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #626262;
}

.Page4_box__2LXw1 {
  width: 100%;
  padding: 0 10% 40px 10%;
  margin-top: 80px;
  background-color: #ffffff;
}
.Page4_title__sxJtm {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: #626262;
}
.Page4_boxInputLeft__34muP {
  height: 210px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 500px;
}
.Page4_inputName__22JuR {
  width: 100%;
  height: 54px;
  border: 4px solid #ff800c;
  box-sizing: border-box;
  border-radius: 21px;
}
.Page4_inputEmail__2RkjK {
  width: 100%;
  height: 54px;
  border: 4px solid #ff800c;
  box-sizing: border-box;
  border-radius: 21px;
}
.Page4_inputPhone__247y4 {
  width: 100%;
  height: 54px;
  border: 4px solid #ff800c;
  box-sizing: border-box;
  border-radius: 21px;
}
.Page4_boxInputRight__BhcCm {
  height: 210px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 500px;
}
.Page4_inputCompanyName__QVcC2 {
  width: 100%;
  height: 54px;
  border: 4px solid #ff800c;
  box-sizing: border-box;
  border-radius: 21px;
}
.Page4_inputCompanySite__xrc-5 {
  width: 100%;
  height: 54px;
  border: 4px solid #ff800c;
  box-sizing: border-box;
  border-radius: 21px;
}
.Page4_boxInputBottom__3knEK {
  width: 500px;
}
.Page4_inputMessage__1vlU6 {
  width: 100%;
  border: 4px solid #ff800c;
  box-sizing: border-box;
  border-radius: 21px;
}
.Page4_btnSendEmail__3wkFC {
  width: 160px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff800c;
  border: 4px solid #ff800c;
  box-sizing: border-box;
  border-radius: 42px;
  cursor: pointer;
}
.Page4_btnSendEmailText__2E607 {
  width: 100%;
  height: 23px;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
}

.Login_Box__2TW60 {
	position: relative;
	width: 81em;
	height: 50em;
	left: 73px;
	top: 117px;
	background-color: #ffffff;
}
.Login_labelWelcome__99jKL {
	position: absolute;
	width: 226px;
	height: 47px;
	left: 531px;
	top: 192px;
	font-family: Raleway;
	font-style: normal;
	font-weight: 500;
	font-size: 40px;
	line-height: 47px;
	z-index: 1;
}
.Login_boxOr__2KJnz {
	position: absolute;
	width: 96px;
	height: 63px;
	left: 600px;
	top: 332px;
	border-radius: 50%;
	z-index: 1;
	background: #ffffff;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.Login_boxOrText__lC-C7 {
	position: relative;
	width: 27px;
	height: 23px;
	left: 33px;
	top: 20px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 23px;
	color: #ff800c;
}
.Login_boxLeft__2AyE- {
	position: relative;
	float: left;
	height: 100%;
	width: 50%;
}
.Login_boxLogo__2nhkj {
	position: relative;
	left: 23px;
	top: 34px;
	cursor: pointer;
}
.Login_Ellipse14__1WXcr {
	width: 217px;
	height: 102px;
	box-sizing: border-box;
}
.Login_Hotqr2__1Szmq {
	position: absolute;
	width: 153px;
	height: 67px;
	left: 29px;
	top: 17px;
}
.Login_boxGoogle__zpM20 {
	position: absolute;
	width: 100px;
	height: 82px;
	left: 413px;
	top: 314px;
}
.Login_labelGoogleText__sFA2b {
	width: 100%;
	height: 23px;
	font-family: Raleway;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 23px;
	text-align: center;
	color: #ff800c;
}
.Login_googleIcon__2az9K {
	width: 100px;
	height: 32.65px;
	margin-top: 20px;
	cursor: pointer;
}
.Login_boxRight__2Pwx6 {
	position: relative;
	float: left;
	height: 100%;
	width: 50%;
	background-color: #ff800c;
}
.Login_boxLogin__2dAX8 {
	position: absolute;
	width: 100%;
	height: auto;
	top: 194px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.Login_boxReg__10Te0 {
	position: absolute;
	width: 100%;
	height: auto;
	top: 120px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.Login_loginLebelText__A7XPc {
	position: relative;
	float: left;
	width: 154px;
	height: 23px;
	font-family: Raleway;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 23px;
	color: #ffffff;
}
.Login_inputEmail__1Gb71 {
	position: relative;
	float: left;
	width: 236px !important;
	height: 46px;
	top: 25px;
}
.Login_inputPass__18hnW {
	position: relative;
	float: left;
	width: 236px;
	height: 46px;
	top: 50px;
}
.Login_btnLogin__G8Nl0 {
	position: relative;
	float: left;
	/* width: 122px;
    height: 46px; */
	top: 85px;
}
.Login_btnLoginTex__2ZFBj {
	position: relative;
	width: 53px;
	height: 23px;
	left: 32px;
	top: 8px;
	font-family: Raleway;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 23px;
	color: #ff800c;
}
.Login_btnSignUp__18pZI {
	position: absolute;
	width: auto;
	height: 23px;
	left: 165px;
	top: 526px;
	font-family: Raleway;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 23px;
	color: #ffffff;
}
.Login_inputBtnSubmit__23yyP {
	width: 122px;
	height: 46px;
}

@media only screen and (max-width: 1440px) {
	.Login_inputEmail__1Gb71 {
		width: 50% !important;
	}
	.Login_inputPass__18hnW {
		width: 50% !important;
	}
}

.SignUp_Box__1yf-K {
	position: relative;
	width: 81em;
	height: 50em;
	left: 73px;
	top: 117px;
	background-color: #ffffff;
}
.SignUp_labelTitle__-yeUt {
	position: absolute;
	width: auto;
	height: 47px;
	left: 432px;
	top: 52px;
	font-family: Raleway;
	font-style: normal;
	font-weight: 500;
	font-size: 40px;
	line-height: 47px;
	z-index: 1;
}
.SignUp_boxOr__2guIf {
	position: absolute;
	width: 96px;
	height: 63px;
	left: 600px;
	top: 332px;
	border-radius: 50%;
	z-index: 1;
	background: #ffffff;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.SignUp_boxOrText__2w1We {
	position: relative;
	width: 27px;
	height: 23px;
	left: 33px;
	top: 20px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 23px;
	color: #ff800c;
}
.SignUp_boxLeft__1SB0e {
	position: relative;
	float: left;
	height: 100%;
	width: 50%;
	background-color: #fff;
}
.SignUp_boxLogo__UABpv {
	position: relative;
	left: 23px;
	top: 34px;
	cursor: pointer;
}
.SignUp_Ellipse14__2EUet {
	width: 217px;
	height: 102px;
	box-sizing: border-box;
}
.SignUp_Hotqr2__e2rlC {
	position: absolute;
	width: 153px;
	height: 67px;
	left: 29px;
	top: 17px;
}
.SignUp_boxGoogle__1RLfp {
	position: absolute;
	width: 120px;
	height: 82px;
	left: 413px;
	top: 314px;
}
.SignUp_labelGoogleText__2HGPQ {
	width: 100%;
	height: 23px;
	font-family: Raleway;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 23px;
	text-align: center;
	color: #ff800c;
}
.SignUp_googleIcon__1eYmv {
	width: 100px;
	height: 32.65px;
	margin-left: 10px;
	margin-top: 20px;
	cursor: pointer;
}
.SignUp_boxRight__12P4J {
	position: relative;
	float: left;
	height: 100%;
	width: 50%;
	background-color: #ff800c;
}
.SignUp_boxSignUp__6WB2t {
	position: absolute;
	width: 238px;
	height: auto;
	left: 50%;
	top: 254px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.SignUp_signupLebelText__XONoH {
	position: relative;
	float: 175px;
	width: auto;
	height: 23px;
	/* left: 32px; */
	font-family: Raleway;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 23px;
	color: #ffffff;
}
.SignUp_inputEmail__1475X {
	position: relative;
	float: left;
	width: 236px;
	height: 46px;
	top: 25px;
}
.SignUp_inputPass__1O3lP {
	position: relative;
	float: left;
	width: 236px;
	height: 46px;
	top: 50px;
}
.SignUp_inputMobile__8Ecyf {
	width: 236px !important;
	height: 46px;
	top: 25px;
}
.SignUp_inputOtp__xGisS {
	text-align: center;
	width: 236px;
}
.SignUp_inputRegForm__1HHQe {
	margin-top: 15px !important;
	width: 236px !important;
}
.SignUp_inputGender__IEXlV {
	width: 236px;
	height: 40px;
	margin-top: 15px;
	margin-bottom: 5px;
}
.SignUp_btnReg__alB9t {
	position: relative;
	float: left;
	width: 162px;
	height: 46px !important;
	top: 85px;
}
.SignUp_btnSignUp__1Inuq {
	position: relative;
	float: left;
	width: 178px;
	height: 46px;
	left: 30px;
	top: 85px;
	background-color: #fff;
	background: #ffffff;
	border: 2px solid #ffffff;
	box-sizing: border-box;
	border-radius: 21px;
	cursor: pointer;
}
.SignUp_inputBtnSignUp__3tbuP {
	position: relative;
	float: left;
	width: 178px;
	height: 46px;
	top: 35px;
}
.SignUp_loginLink__b8bWZ {
	position: absolute;
	width: auto;
	height: 23px;
	left: 135px;
	top: 526px;
	font-family: Raleway;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 23px;
	color: #ffffff;
}

@media only screen and (max-width: 1440px) {
	.SignUp_inputMobile__8Ecyf {
		width: 50% !important;
	}
	.SignUp_boxSignUp__6WB2t {
		width: 50%;
	}
	.SignUp_inputRegForm__1HHQe {
		width: 50% !important;
	}
	.SignUp_inputGender__IEXlV {
		width: 50%;
	}
	.SignUp_inputOtp__xGisS {
		width: 50%;
	}
}

.content_boxMobile__MjvC3 {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.content_boxMobile__MjvC3::-webkit-scrollbar {
    display: none;
}
:root {
  --white: #fff;
  --active-color: #00aeb0;
}

* {
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  height: 100%;
  transition: background 0.4s ease;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

input[type="range"] {
  height: 5px;
  -webkit-appearance: none;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
  background: #3b7677;
  transition: background 0.2s ease;
  cursor: pointer;
}

.audio-player {
  max-width: 350px;
  padding: 24px;
  border-radius: 20px;
  box-shadow: 0 28px 28px rgba(0, 0, 0, 0.2);
  margin: auto;
  color: #fff;
  color: var(--white);
  background: gray;
}

.artwork {
  border-radius: 120px;
  display: block;
  margin: auto;
  height: 200px;
  width: 200px;
}

.track-info {
  text-align: center;
  z-index: 1;
  position: relative;
}

.title {
  font-weight: 700;
  margin-bottom: 4px;
  text-align: justify;
  overflow-wrap: anywhere;
}

.artist {
  font-weight: 300;
  margin-top: 0;
}

.audio-controls {
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: 0 auto 15px;
}

.audio-controls .prev svg,
.audio-controls .next svg {
  width: 35px;
  height: 35px;
}

.audio-controls .play svg,
.audio-controls .pause svg {
  height: 40px;
  width: 40px;
}

.audio-controls path {
  fill: #fff;
  fill: var(--white);
}

.color-backdrop {
  background: linear-gradient(45deg, #00aeb0 20%, transparent 100%)
    no-repeat;
  background: linear-gradient(45deg, var(--active-color) 20%, transparent 100%)
    no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.color-backdrop.playing {
  -webkit-animation: colorChange 20s alternate infinite;
          animation: colorChange 20s alternate infinite;
}

@-webkit-keyframes colorChange {
  from {
    -webkit-filter: hue-rotate(0deg);
            filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(360deg);
            filter: hue-rotate(360deg);
  }
}

@keyframes colorChange {
  from {
    -webkit-filter: hue-rotate(0deg);
            filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(360deg);
            filter: hue-rotate(360deg);
  }
}

.style_gridItems__30R1U {
    height: auto;
    width: 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.style_boxContent__29PM7 {
    position: relative;
    height: 230px;
    width: 240px;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    background: #F9F9F9;
    border: 0px solid #FFF;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}
.style_labelTitle__2chO3 {
    position: relative;
    float: left;
    width: auto;
    height: 23px;
    padding: 0 5px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #626262;
}
.style_labelshortDescription__eMrHD {
    position: relative;
    float: left;
    width: auto;
    height: 23px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #626262;
}
.style_Icon__3Dm99 {
    position: relative;
    float: left;
    width: 150px;
    height: 100px;
}
.style_IconQr__3JUfG {
    height: 30px;
    width: 25px;
    position: absolute;
    bottom: 0px;
    right: 0px;
}
