@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

@import "react-date-range/dist/styles.css";
@import "react-date-range/dist/theme/default.css";
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: "Roboto Condensed", sans-serif;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
/* input:-internal-autofill-selected {
  appearance: menulist-button;
  background-color: red !important;
  background-image: none !important;
  color: blue !important;
} */

/* -------------- Arrow Stepper CSS ------------------  */

.Arrows {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 50px;
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#ff800c),
		to(#ff800c)
	);
	/* background: linear-gradient(to right, #24d191, #30b2f3); */
}

.Arrows--step {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	position: relative;
	max-width: 200px;
}

.Arrows--step--arrow,
.Arrows--step--content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.Arrows--step--content {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 0 20px;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.Arrows--step--number {
	font-size: 16px;
	font-size: 1rem;
	font-weight: 600;
	opacity: 0.5;
}

.Arrows--step--number::after {
	content: ".";
	margin-right: 4px;
}

.Arrows--step--label {
	font-size: 12px;
	font-size: 0.75rem;
	font-weight: 600;
	color: #fff;
}

.Arrows--step__current .Arrows--step--arrow {
	fill: #e0e0e0;
}
.Arrows--step__current .Arrows--step--label {
	color: #000;
	opacity: 0.5;
}

@media (min-width: 540px) {
	.Arrows--step--number {
		font-size: 0;
	}

	.Arrows--step--label {
		font-size: 1.2rem;
	}
}

/* ----------------------- Swiper Js Styles ------------------------ */

.swiper {
	width: 100%;
	height: 100%;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;

	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

/* styles for marquee class */

.marquee {
	width: 100%;
	line-height: 50px;
	color: white;
	white-space: nowrap;
	overflow: hidden;
	box-sizing: border-box;
}
.marquee p {
	display: inline-block;
	padding-left: 100%;
	animation: marquee 15s linear infinite;
}
@keyframes marquee {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(-100%, 0);
	}
}

/* custome color css for react date range picker third party component  */

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
	color: #fc800e;
}

.rdrDayToday .rdrDayNumber span:after {
	background: #fc800e;
}

.rdrDateDisplayItemActive {
	border-color: #fc800e;
}
