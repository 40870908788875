.box {
  position: relative;
  width: 100%;
  padding: 0 10%;
  background-color: #ffffff;
}
.boxTitle {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: #ff800c;
}
.boxsubTitle {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #626262;
}
.boxContent {
  position: relative;
  left: 57px;
  top: 100px;
}
.boxContentboxes {
  position: relative;
  width: 373px;
  height: 266px;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.boxContentboxesText {
  position: absolute;
  width: 317px;
  height: 202px;
  left: 26px;
  top: 23px;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #626262;
}
