.box {
  width: 100%;
  padding: 0 5%;
  background-color: #ffffff;
}
.boxLogo {
  position: absolute;
  top: 1.4375em;
  cursor: pointer;
}
.Ellipse14 {
  width: 217px;
  height: 102px;
  box-sizing: border-box;
}
.HOTQr {
  width: 150px;
  height: 90px;
}
.boxHead {
  position: relative;
  width: 825.25px;
  height: 165px;
  margin-top: 76px;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.boxHeadTitle {
  position: relative;
  width: 100%;
  height: 35px;
  left: 200px;
  top: 34px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: #ff800c;
}
.boxHeadText {
  position: absolute;
  width: 454px;
  height: 46px;
  left: 184.62px;
  top: 106.5px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #626262;
}
.boxHeadButton {
  position: absolute;
  width: 311px;
  height: 46px;
  left: 245px;
  top: 102px;
  background: #ff800c;
  border-radius: 8px;
  cursor: pointer;
}
.boxHeadButtonText {
  position: relative;
  width: 147px;
  height: 23px;
  left: 14px;
  top: 11px;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
}
.labelTitle {
  margin-top: 40px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #626262;
}
.boxStar {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.boxStarVerticalLine {
  width: 0px;
  height: 70px;
  border: 2px solid #ff800c;
}
.starImg {
  width: 600px;
  height: 600px;
}
.vectorImg {
  position: absolute;
  width: 320px;
  height: 292px;
  left: 136px;
  top: 234px;
}
.boxImg1 {
  position: absolute;
  width: 88.59px;
  height: 90px;
  left: 253px;
  top: 144px;
}
.boxImg2 {
  position: absolute;
  width: 88.59px;
  height: 90px;
  left: 459px;
  top: 320px;
}
.boxImg3 {
  position: absolute;
  width: 88.59px;
  height: 90px;
  left: 390px;
  top: 570px;
}
.boxImg4 {
  position: absolute;
  width: 88.59px;
  height: 90px;
  left: 119px;
  top: 570px;
}
.boxImg5 {
  position: absolute;
  width: 88.59px;
  height: 90px;
  left: 42px;
  top: 320px;
}
.qrIcon {
  position: absolute;
}
.icon {
  position: absolute;
}
.starText {
  position: absolute;
  width: auto;
  height: 23px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #626262;
}
.boxRound {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: linear-gradient(0deg, #ff800c, #ff800c), #ff800c;
}
