.box {
  position: relative;
  padding: 0 10%;
  height: 64rem;
}
.boxBgImage {
  position: absolute;
  width: 90em;
  height: 64em;
}
.boxContent {
  position: relative;
  background-color: #ffffff;
  /* width: 82.62em; */
  height: 42.43em;
  /* left: 59px; */
  top: 173px;
}
.boxLogo {
  position: relative;
  left: 28px;
  top: 33px;
  cursor: pointer;
}
.Ellipse14 {
  width: 196px;
  height: 102px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
}
.HOTQr {
  width: 150px;
  height: 90px;
}
.boxMenu {
  position: absolute;
  width: 496px;
  height: 69px;
  right: 28px;
  top: 33px;
  border: 4px solid #ff800c;
  box-sizing: border-box;
  border-radius: 42px;
}
.boxMenuItems {
  position: absolute;
  width: 108px;
  height: 28px;
  top: 17px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #ff800c;
  cursor: pointer;
}
.labelTitle {
  position: absolute;
  width: auto;
  height: 59px;
  /* left: 374px; */
  top: 228px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 59px;
  color: #ff800c;
}
.labelText {
  position: absolute;
  width: 845px;
  height: 232px;
  /* left: 238px; */
  top: 297px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  text-align: center;
  color: #403e3e;
}
