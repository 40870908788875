.box {
  width: 100%;
  padding: 0 10%;
  margin-top: 80px;
  background-color: #ffffff;
}
.title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ff800c;
}
.subTitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #626262;
}
.boxContent {
  padding: 35px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.toolIcon {
  width: 131.12px;
  height: 100px;
  object-fit: contain;
}
.toolText {
  display: flex;
  flex-direction: column;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #626262;
}
