.box {
	position: relative;
	width: 100%;
	height: 950px;
	padding: 0 5%;
	background-color: #ffffff;
}
.boxLogo {
	position: absolute;
	top: 2em;
	cursor: pointer;
}
.Ellipse14 {
	width: 217px;
	height: 102px;
	box-sizing: border-box;
}
.HOTQr {
	width: 150px;
	height: 90px;
}
.boxHead {
	position: relative;
	width: 825.25px;
	height: 299.75px;
	margin-top: 76px;
	background: #f9f9f9;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
}
.boxHeadTitle {
	position: relative;
	width: 100%;
	height: 35px;
	text-align: center;
	top: 49px;
	font-family: Raleway;
	font-style: normal;
	font-weight: 500;
	font-size: 30px;
	line-height: 35px;
	color: #ff800c;
}
.boxHeadText {
	position: absolute;
	width: 454px;
	height: 46px;
	left: 184.62px;
	top: 106.5px;
	font-family: Raleway;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 23px;
	text-align: center;
	color: #626262;
}
.boxHeadButton {
	position: absolute;
	width: 198.25px;
	height: 32.75px;
	left: 302.88px;
	top: 212px;
	background: #ff800c;
	border-radius: 8px;
	cursor: pointer;
}
.boxHeadButtonText {
	position: relative;
	width: 147px;
	height: 23px;
	left: 25.62px;
	top: 4.87px;
	font-family: Raleway;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 23px;
	text-align: center;
	color: #ffffff;
}
.QrLogo {
	position: relative;
	width: 252px;
	height: 256px;
	margin-top: 40px;
	z-index: 5;
}
.qrText {
	font-family: Raleway;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 23px;
	text-align: center;
	color: #626262;
}
