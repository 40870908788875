.box {
  width: 100%;
  padding: 0 10%;
  margin-top: 80px;
  background-color: #ffffff;
}
.boxTitle {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ff800c;
}
.boxsubTitle {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #626262;
}
.boxTitle2 {
  margin-top: 20px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ff800c;
}
.labelText {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #626262;
}
.Icon {
  margin-top: 10px;
  width: 298.79px;
  height: 300px;
}
