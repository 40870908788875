.box {
  width: 100%;
  padding: 0 5%;
  background-color: #ffffff;
}
.boxLogo {
  position: absolute;
  top: 1.4375em;
  cursor: pointer;
}
.HOTQr {
  width: 150px;
  height: 90px;
}
.boxHead {
  position: relative;
  width: 825.25px;
  height: 299.75px;
  margin-top: 76px;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.boxHeadTitle {
  position: relative;
  float: left;
  width: 100%;
  height: 35px;
  top: 49px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ff800c;
}
.boxHeadText {
  position: relative;
  float: left;
  width: 652px;
  height: 46px;
  left: 87px;
  top: 56.5px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #626262;
}
.boxHeadButton {
  position: absolute;
  width: 311px;
  height: 46px;
  left: 246px;
  top: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff800c;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
}
.boxHeadButtonText {
  position: relative;
  float: left;
  width: 100%;
  height: 23px;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
}
