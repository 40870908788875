.box {
  width: 100%;
  margin-top: 80px;
  padding: 0 10%;
  background-color: #ffffff;
}
.qrIcon {
  width: 352.3px;
  height: 350px;
}
.middleLine {
  width: 6px;
  height: 450px;
  background: #ff800c;
}
.Text {
  display: flex;
  flex-direction: column;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #626262;
}
