.box {
  width: 100%;
  padding: 0 10% 40px 10%;
  margin-top: 80px;
  background-color: #ffffff;
}
.title {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ff800c;
}
.boxTabelCell {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.tableHeaderText {
  width: 199px;
  height: 27px;
  margin-bottom: 28px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  color: #ffffff;
}
.boxHeaderPlanBtn {
  width: 201px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 12px;
  cursor: pointer;
}
.boxHeaderPlanBtnText {
  cursor: pointer;
  width: 136px;
  height: 27px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  color: #ffffff;
}
.tableBodyRow {
  height: 66px;
  border-right: 4px solid #ff800c;
}
.tableBodyText {
  width: 136px;
  height: 27px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  color: #626262;
}
