.box {
  position: relative;
  padding: 0 10%;
  background: #ff800c;
}
.boxInputMain {
  display: flex;
  padding: 20px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #ffffff;
}
.boxInputTitle {
  width: 100%;
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #2d2c2c;
  margin-bottom: 10px;
}
.inputName {
  width: 75%;
  height: 54px;
}
.inputEmail {
  width: 75%;
  height: 54px;
}
.inputSubject {
  width: 75%;
  height: 54px;
}
.inputMessage {
  width: 75%;
}
.btnSendMessage {
  position: relative;
  width: 160px;
  height: 52px;
  background: #ff800c;
  border: 4px solid #ff800c;
  box-sizing: border-box;
  border-radius: 42px;
  cursor: pointer;
}
.btnSendMessageText {
  position: absolute;
  width: 145px;
  height: 32px;
  left: 6px;
  top: 10px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #f9f9f9;
}
.boxContact {
  padding: 30px 30px 20px 50px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.boxContactTextTitle {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: #ffffff;
}
.boxContactText {
  margin-top: 30px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 35px;
  color: #ffffff;
}
.boxIcon {
  height: 30px;
  margin: 10px 0;
}
.iconText {
  margin-left: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 23px;
  line-height: 20px;
  color: #ffffff;
}
.boxFooter {
  position: relative;
  width: 100%;
}
.labelFooterTitle {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 7px;
}
.labelFooterText {
  margin: 4px 0;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  cursor: pointer;
}
.boxLogoContainer {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  width: 250px;
}
.footerSocialIconLinkedIn {
  width: 40px;
  height: 40px;
  border-radius: 200px;
  cursor: pointer;
}
.footerSocialIcon {
  width: 40px;
  height: 40px;
  /* margin-left: 39px; */
  border-radius: 200px;
  cursor: pointer;
}
.footerSocialIconMail {
  width: 66.35px;
  height: 50px;
  /* margin-left: 27px; */
  border-radius: 200px;
  cursor: pointer;
}
.footerCopyrights {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
}
