.Box {
	position: relative;
	width: 81em;
	height: 50em;
	left: 73px;
	top: 117px;
	background-color: #ffffff;
}
.labelWelcome {
	position: absolute;
	width: 226px;
	height: 47px;
	left: 531px;
	top: 192px;
	font-family: Raleway;
	font-style: normal;
	font-weight: 500;
	font-size: 40px;
	line-height: 47px;
	z-index: 1;
}
.boxOr {
	position: absolute;
	width: 96px;
	height: 63px;
	left: 600px;
	top: 332px;
	border-radius: 50%;
	z-index: 1;
	background: #ffffff;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.boxOrText {
	position: relative;
	width: 27px;
	height: 23px;
	left: 33px;
	top: 20px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 23px;
	color: #ff800c;
}
.boxLeft {
	position: relative;
	float: left;
	height: 100%;
	width: 50%;
}
.boxLogo {
	position: relative;
	left: 23px;
	top: 34px;
	cursor: pointer;
}
.Ellipse14 {
	width: 217px;
	height: 102px;
	box-sizing: border-box;
}
.Hotqr2 {
	position: absolute;
	width: 153px;
	height: 67px;
	left: 29px;
	top: 17px;
}
.boxGoogle {
	position: absolute;
	width: 100px;
	height: 82px;
	left: 413px;
	top: 314px;
}
.labelGoogleText {
	width: 100%;
	height: 23px;
	font-family: Raleway;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 23px;
	text-align: center;
	color: #ff800c;
}
.googleIcon {
	width: 100px;
	height: 32.65px;
	margin-top: 20px;
	cursor: pointer;
}
.boxRight {
	position: relative;
	float: left;
	height: 100%;
	width: 50%;
	background-color: #ff800c;
}
.boxLogin {
	position: absolute;
	width: 100%;
	height: auto;
	top: 194px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.boxReg {
	position: absolute;
	width: 100%;
	height: auto;
	top: 120px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.loginLebelText {
	position: relative;
	float: left;
	width: 154px;
	height: 23px;
	font-family: Raleway;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 23px;
	color: #ffffff;
}
.inputEmail {
	position: relative;
	float: left;
	width: 236px !important;
	height: 46px;
	top: 25px;
}
.inputPass {
	position: relative;
	float: left;
	width: 236px;
	height: 46px;
	top: 50px;
}
.btnLogin {
	position: relative;
	float: left;
	/* width: 122px;
    height: 46px; */
	top: 85px;
}
.btnLoginTex {
	position: relative;
	width: 53px;
	height: 23px;
	left: 32px;
	top: 8px;
	font-family: Raleway;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 23px;
	color: #ff800c;
}
.btnSignUp {
	position: absolute;
	width: auto;
	height: 23px;
	left: 165px;
	top: 526px;
	font-family: Raleway;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 23px;
	color: #ffffff;
}
.inputBtnSubmit {
	width: 122px;
	height: 46px;
}

@media only screen and (max-width: 1440px) {
	.inputEmail {
		width: 50% !important;
	}
	.inputPass {
		width: 50% !important;
	}
}
