.gridItems {
    height: auto;
    width: 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.boxContent {
    position: relative;
    height: 230px;
    width: 240px;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    background: #F9F9F9;
    border: 0px solid #FFF;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}
.labelTitle {
    position: relative;
    float: left;
    width: auto;
    height: 23px;
    padding: 0 5px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #626262;
}
.labelshortDescription {
    position: relative;
    float: left;
    width: auto;
    height: 23px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #626262;
}
.Icon {
    position: relative;
    float: left;
    width: 150px;
    height: 100px;
}
.IconQr {
    height: 30px;
    width: 25px;
    position: absolute;
    bottom: 0px;
    right: 0px;
}