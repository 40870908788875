.box {
  position: relative;
  height: 64em;
  padding: 0 10%;
  background: #ffffff;
}
.labelTitle {
  width: auto;
  height: 47px;
  margin-top: 20px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 47px;
  color: #ff800c;
}
.labelText {
  width: 767px;
  /* height: 105px; */
  /* left: 331px; */
  /* margin-top: 63px; */
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #626262;
}
.interRemoveBgPreview {
  position: relative;
  width: 307.06px;
  height: 300px;
  /* left: 553px; */
  /* margin-top: 168px; */
}
.labelSubTitle {
  width: 150px;
  /* height: 47px; */
  /* left: 640px; */
  /* margin-top: 480px; */
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 47px;
  color: #ff800c;
}
.boxTable {
  position: relative;
  max-width: 100%;
  background: #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.boxTitleLabel {
  width: 100%;
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 65px;
  color: #ffffff;
}
.boxTableTopbar {
  display: flex;
  align-items: center;
  width: 100%;
  height: 65px;
  background: #ff800c;
}
.boxMiddelBar {
  position: absolute;
  width: 4px;
  height: 80.5%;
  top: 5px;
  background: #ff800c;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.boxRowBar {
  width: 100%;
  height: 4px;
  background: #ff800c;
}
.labelTabelText {
  font-family: "Raleway", sans-serif;
  width: 40%;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #626262;
}

.labelTabelTextLastNode {
  font-family: "Raleway", sans-serif;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #626262;
}
