.box {
  width: 100%;
  padding: 0 10%;
  margin-top: 62px;
  background-color: #ffffff;
}
.title {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: #ff800c;
}
.subTitle {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #626262;
}
.boxTable {
  width: 100%;
  padding: 0 80px;
  margin-top: 20px;
}
.boxTableRow {
  width: 100%;
  /* background-color: burlywood; */
}
.tableLeftText {
  width: 100%;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #000000;
}
.tableRightText {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #626262;
}
.tableRow {
  width: 100%;
  height: 2px;
  border: 1px solid #626262;
}
.tableMiddleColumn {
  width: 2px;
  height: 100%;
  border: 1px solid #626262;
}
