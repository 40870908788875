.box {
  width: 100%;
  padding: 0 10%;
  margin-top: 80px;
  background-color: #ffffff;
}
.boxTitle {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ff800c;
}
.labelText {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 0 20px;
  height: 100%;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #626262;
}
